import { useMemo } from 'react'
import { cleanFlags } from '@/services/FeatureFlags/utils'
import { useAtomValue } from 'jotai'
import { featureFlagsAtom } from '@/state/FeatureFlags/atoms'
import { useMarketingFeatureFlagsQuery } from '@/services/FeatureFlags/queries'

export const useFeatureFlags = () => useAtomValue(featureFlagsAtom)

export const useMarketingFeatureFlags = () => {
  const { isFetched, isPending, error, data } = useMarketingFeatureFlagsQuery()

  return useMemo(() => {
    const flags = cleanFlags(data)
    return { isPending, error, flags, isFetched }
  }, [data, isPending, error, isFetched])
}
